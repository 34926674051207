<template>
    <div v-show="isShow">
        <div class="link-dialog-box">
            <div class="title">添加超链接</div>
            <div class="input-block">
                <input type="text" name="" id="" placeholder="http(s)://" v-model="url">
                <input type="text" name="" id="" placeholder="名称" v-model="name">
            </div>
            <div class="button-block">
                <div class="button" @click="cancel">取消</div>
                <div class="button" @click="addLink">确定</div>
            </div>
        </div>
        <div class="shadow"></div>
    </div>
</template>

<script>
export default {
  name: 'LinkDialog',
  components: {
  },
  data(){
      return {
          isShow: false,
          url:'', // 链接地址
          name:'', // 链接名称
      }
  },
  methods:{
      show(){
          this.isShow = !this.isShow
          this.url = ''
          this.name = ''
      },
      cancel(){
          this.show()
          this.url = ''
          this.name = ''
          this.$emit('cancel')
      },
      addLink(){
          let link = {}
          if(this.url.includes('http') || this.url.includes('https')){
            link.url = this.url
          } else {
            link.url = 'http://' + this.url
          }
          link.name = this.name ? this.name : this.url
          this.$emit('addLink', link)
          this.isShow = !this.isShow
      },
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.link-dialog-box {
    max-width: 625px;
    margin: 0 auto;
    height: 160px;
    background: #fefefe;
    position: absolute;
    border-radius: 10px;
    z-index: 999;
    padding: 10px 20px;
    left: 30px;
    right: 30px;
    top: 0;
    bottom: 0;
    margin:auto;
    .title {
        color: #3f3f3f;
        text-align: center;
        font-size: 20px;
    }
    .input-block {
        margin: 10px 0;
        border: 1px solid #ddd;
        border-radius: 5px;
        input {
            height: 40px;
            width: 100%;
            box-sizing: border-box;
            background: #fefefe;
            padding: 0 10px;
            border: none;
            border-radius: 5px;
            border-bottom: 1px solid #ddd;
        }
    }
    .button-block {
        display: flex;
        justify-content: space-between;
        margin: 0 10px;
        .button {
            width: 80px;
            height: 35px;
            font-size: 18px;
            text-align: center;
            line-height: 35px;
            border-radius: 5px;
            color: #ea6f7b;
        }
    }
}
</style>
