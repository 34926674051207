<template>
    <div>
        <div class="create-box" id="create-box" v-if="!$store.state.showLabel">
              <div id="textInput">
                  <div class="titleBox" v-show="false">
                      <textarea ref="test" placeholder="请输入标题" class="textarea" v-model="title" maxlength="100" rows="1"></textarea>
                      <i>{{titleIndex}}</i>
                  </div>
                  <div @click="handleSelection" @input="handleSelection" @paste="handlePaste" class="content" id="editContext"
                       contenteditable="true" placeholder='点击此处说点什么...' ref="input-box"></div>
              </div>
              <div class="edit-footer"  v-show="showToolBar">
                <div class="row" v-show="Object.keys(selectedTopic).length > 0">
                    <span class="selected-label">{{ selectedTopic.name }}</span>
                    <span class="delete-label" @click="deleteLabel">✖</span>
                </div>
                <div class="row">
                    <div>
                        <van-uploader :after-read="onRead" accept="image/*">
                            <img src="../assets/images/photo.png" alt="" width="32" height="32">
                        </van-uploader>
                        <img src="../assets/images/bold.png" alt="" width="28" height="28" v-if="changeToBold" @click="editBold">
                        <img src="../assets/images/bold-highlight.png" alt="" width="28" height="28" v-else @click="editBold">
                        <img src="../assets/images/label.png" alt="" width="32" height="32" @click="showLabel">
                        <img src="../assets/images/link.png" alt="" width="32" height="32" @click="addLinkToContext">
                    </div>
                    <div class="create-button" v-if="$store.state.userAgent === 'PC'" @click="handleCreate">发布</div>
                </div>
              </div>
              <link-dialog ref="link" @addLink="addLink" @cancel="cancel"/>
              <delete-img ref="deleteImg" @deleteImg="deleteImg"/>
        </div>
        <div v-else>
            <panel :lists="labelArr" style="padding-top: 50px;" @selectPanel="selectPanel" @selectPanelDB="selectPanelDB"/>
        </div>
        <confirm-save ref="confirmSave"/>
    </div>
</template>

<script>
import LinkDialog from '../components/dialog/LinkDialog.vue'
import DeleteImg from '../components/dialog/DeleteImg.vue'
import Panel from '../components/panel/index.vue'
import ConfirmSave from '../components/dialog/ConfirmSave.vue'
import { createArticle, upload, deleteUpload, createDraft, queryDraftById, deleteDraftByDid, updateDraftByDid } from '@/api/api'

export default {
    name: 'CreateArticle',
    created(){
        this.$bus.$on('handleCreate',this.handleCreate)
        this.$bus.$on('addLabel',this.addLabel)
        this.$bus.$on('cancelSelect',this.cancelSelect)
        if(this.$route.query.did){
            this.getData()
        }
    },
    mounted(){
        this.$refs.test.focus() 
        this.textarea = document.querySelector('textarea')
        this.textarea.addEventListener('input', this.autoHeight)
        this.textInput = document.getElementById('textInput')
        this.textInput.addEventListener('click', this.handleCursorClick)
        this.textInput.addEventListener('focus', this.focusHandler, true)
        if(this.$store.state.userAgent === 'PC'){
            document.getElementById("create-box").style.cssText= 'margin-top: 20px; height:100%;';
        }
        this.$bus.$on('saveDraft',this.saveDraft)
        this.$bus.$on('handleCancel',this.handleCancel)
        this.$bus.$on('deleteDraft',this.deleteDraft)
        this.textInput.addEventListener('keydown', this.keydown);
        document.addEventListener('click', this.domClick)
        this.initLabel() // 初始化标签
        this.labelArrTemp = this.labelArr // 初始化标签
    },
    destroyed(){
        this.textarea.removeEventListener("input", this.autoHeight, true)
        this.textInput.removeEventListener('click', this.handleCursorClick)
        this.textInput.removeEventListener('focus', this.focusHandler, false)
        this.textInput.removeEventListener('keydown', this.keydown, false);
        document.removeEventListener('keydown', this.domClick, false);
        this.$store.commit('Set_ShowLabel_State', false)
        this.$bus.$off('handleCreate')
        this.$bus.$off('addLabel')
        this.$bus.$off('cancelSelect')
        this.$bus.$off('saveDraft')
        this.$bus.$off('handleCancel')
        this.$bus.$off('deleteDraft')
    },
    components: {
        LinkDialog,
        DeleteImg,
        Panel,
        ConfirmSave,
    },
    data:()=>{
        return {
            title:'',
            textarea: null,
            titleIndex: 100,
            changeToBold: true,
            context_html: '',
            context: '',
            imgUrl:'',
            miniImgUrl:'', // 缩略图
            cursorRange:'', // 保存光标对象，用于记录光标位置
            focusElement: '',
            labelArrTemp: [], // 标签列表临时
            // labelArrTemp: seo.config.labelArr, // 标签列表临时
            labelArr: [], // 标签列表
            // labelArr: seo.config.labelArr, // 标签列表
            selectedTopic:{},
            showToolBar: false,
        }
    },
    methods:{
        getData(){
            queryDraftById({ did: this.$route.query.did }).then((res)=>{
                let result = res.data.data[0]
                this.title = result.title
                this.context_html = result.context_html
                this.context = result.context
                this.imgUrl = result.imgUrl
                this.miniImgUrl = result.miniImgUrl
                document.getElementById('editContext').innerHTML = result.context_html
                if(this.imgUrl){
                    this.imgClickBind()
                }
            })
        },
        async initLabel(){
            let resData = JSON.parse(sessionStorage.getItem('labelArr'))
            this.labelArr = resData.map(item => ({
                id: item.id,
                title: item.name,
                showOptions: false,
                selected: false,
                options:[] 
            }));
            // 过滤两种分类：推荐和最新
            this.labelArr = this.labelArr.filter(item => item.id !== 'hot' && item.id !== 'new');
        },
        // 图片绑定点击事件
        imgClickBind(){
            let imgUrlArr = this.imgUrl.split(',')
            for(let i=0; i < imgUrlArr.length; i++){
                let imgDom = document.getElementById(imgUrlArr[i])
                imgDom.addEventListener('click', ()=>{
                    this.$refs.deleteImg.show()
                    this.$refs.deleteImg.bindImgId(imgUrlArr[i])
                })
            }
        },
        handleCreate(){
            this.context_html = document.getElementById('editContext').innerHTML
            this.context = document.getElementById('editContext').textContent
            if(this.context_html.trim()){
                this.$bus.$emit('showLoading') // 打开loading
                let params = {
                    "title": this.title,
                    "context": this.context,
                    "context_html": this.context_html,
                    "imgUrl": this.imgUrl,
                    "miniImgUrl": this.miniImgUrl,
                    "uid": JSON.parse(localStorage.getItem('userInfo'))[0].uid,
                    'label': this.selectedTopic.id, // 用空格隔开，最多选一个标签
                    'topics': '', // 用#号隔开，最多参与五个话题
                }
                // 调接口
                createArticle(params).then(() => {
                    this.$notify({
                        message: '发布成功',
                        duration: 1000,
                        background: '#ec745b'
                    })
                    // 删除草稿
                    if(this.$route.query.did){
                        deleteDraftByDid({ did: this.$route.query.did })
                    }
                    this.resetText()
                    this.$router.push('/')
                    this.$bus.$emit('showLoading') // 关闭loading
                }).catch((error) => {
                    setTimeout(()=>{
                        this.$bus.$emit('showLoading') // 关闭loading
                    }, 0)
                    console.warn(error)
                })
            } else {
                this.$notify({
                    message: '小天没有检测到内容',
                    duration: 1000,
                    background: '#ea6f7b'
                })
            }
        },
        saveDraft(){
            if(this.context_html.trim()){
                this.$bus.$emit('showLoading') // 打开loading
                let params = {
                    "title": this.title,
                    "context": this.context,
                    "context_html": this.context_html,
                    "imgUrl": this.imgUrl,
                    "miniImgUrl": this.miniImgUrl,
                    "uid": JSON.parse(localStorage.getItem('userInfo'))[0].uid,
                }
                if(this.$route.query.did){
                    // 更新草稿
                    params.did = this.$route.query.did
                    updateDraftByDid(params).then(()=>{
                        this.$notify({
                            message: '已保存至草稿箱',
                            duration: 1000,
                            background: '#ec745b'
                        })
                        this.resetText()
                        this.$router.push('/')
                        this.$bus.$emit('showLoading') // 关闭loading
                    }).catch((error) => {
                        setTimeout(()=>{
                            this.$bus.$emit('showLoading') // 关闭loading
                        }, 0)
                        console.warn(error)
                    })
                } else {
                    // 创建草稿
                    createDraft(params).then(() => {
                        this.$notify({
                            message: '已保存至草稿箱',
                            duration: 1000,
                            background: '#ec745b'
                        })
                        this.resetText()
                        this.$router.push('/')
                        this.$bus.$emit('showLoading') // 关闭loading
                    }).catch((error) => {
                        setTimeout(()=>{
                            this.$bus.$emit('showLoading') // 关闭loading
                        }, 0)
                        console.warn(error)
                    })
                }
            } else {
                this.$notify({
                    message: '小天没有检测到内容',
                    duration: 1000,
                    background: '#ea6f7b'
                })
            }
        },
        deleteDraft(){
            if(this.imgUrl){
                let imgUrlArr = this.imgUrl.split(',')
                for(let i=0; i < imgUrlArr.length; i++){
                    deleteUpload({ imgUrl: imgUrlArr[i] })
                }
            }
            if(this.$route.query.did){
                deleteDraftByDid({ did: this.$route.query.did })
            }
            this.resetText()
        },
        handleCancel(){
            this.context_html = document.getElementById('editContext').innerHTML
            let textContent = document.getElementById('editContext').textContent
            if(this.title.trim() !== '' || textContent.trim() !== ''){
                this.$refs.confirmSave.show()
                this.$bus.$off('handleCancel')
            } else {
                this.$router.back()
                this.$bus.$off('handleCancel')
            }
        },
        handleCursorClick(){
            this.changeToBold = true
        },
        cancel(){
            window.getSelection().removeAllRanges() // 移除所有光标对象
            window.getSelection().addRange(this.cursorRange) // 取消时恢复光标位置
        },
        // 清空文本
        resetText(){
            this.title = ''
            this.context = ''
            this.context_html = ''
            this.imgUrl = ''
            this.miniImgUrl = ''
        },
        autoHeight(e){
            this.textarea.style.height = '30px';
            this.textarea.style.height = e.target.scrollHeight + 'px';
        },
        handleSelection(val){
            if (val.inputType !== "insertFromPaste") {
                this.context_html = val.target.innerHTML
                this.context = val.target.textContent
                this.showToolBar = true
                return;
            }
            this.clearFormatting();
        },
        // 加粗功能
        editBold(){
            this.changeToBold = !this.changeToBold
            this.cursorRange = window.getSelection().getRangeAt(0) // 保存光标对象
            if(!this.changeToBold){
                let boldText = window.getSelection().toString() // 获取选区文字
                this.cursorRange.deleteContents() // 删除选区文字
                window.getSelection().removeAllRanges() // 移除所有光标对象
                // 创建加粗元素
                const boldDom = document.createElement('b')
                this.cursorRange.surroundContents(boldDom)
                boldDom.innerText = boldText
                window.getSelection().addRange(this.cursorRange) // 必要时，恢复光标位置
            } else {
                let boldText = window.getSelection().toString() // 获取选区文字
                this.cursorRange.deleteContents() // 删除选区文字
                window.getSelection().removeAllRanges() // 移除所有光标对象
                // 创建加粗元素
                const boldDom = document.createElement('span')
                this.cursorRange.surroundContents(boldDom)
                boldDom.innerText = boldText
                window.getSelection().addRange(this.cursorRange) // 必要时，恢复光标位置
            }
            // 更新需要上传的dom和content
            this.context = document.getElementById('editContext').textContent
            this.context_html = document.getElementById('editContext').innerHTML
        },
        // 添加链接功能
        addLink(params){
            window.getSelection().removeAllRanges() // 移除所有光标对象
            const link = params.url;
            const linkDom = document.createElement('a')
            linkDom.href = link;
            linkDom.innerText = params.name;
            this.cursorRange.insertNode(linkDom) // 光标处插入节点元素
            window.getSelection().addRange(this.cursorRange) // 恢复上一次的光标位置
            window.getSelection().collapseToEnd()
            // 更新需要上传的dom和content
            this.context = document.getElementById('editContext').textContent
            this.context_html = document.getElementById('editContext').innerHTML
        },
        onRead(file) {
            if(/\/(?:jpeg|png)/i.test(file.file.type)){
                let canvas = document.createElement('canvas') // 创建Canvas对象(画布)
                let context = canvas.getContext('2d') 
                let img = new Image()
                let timestamp = Date.now()
                img.src = file.content // 指定图片的DataURL(图片的base64编码数据)
                img.onload = () => {
                    // 指定canvas画布大小，该大小为最后生成图片的大小
                    canvas.width = img.width > 595 ? 595 * 2 : img.width
                    canvas.height = img.width > 595 ? (595 * 2 / img.width * img.height) : img.height
                    context.drawImage(img, 0, 0, canvas.width, canvas.height)
                    if(file.file.size>200000){
                        file.content = canvas.toDataURL('image/jpeg', 0.5) // 0.2为默认压缩质量
                    } else {
                        file.content = canvas.toDataURL('image/jpeg', 1) // 0.2为默认压缩质量
                    }
                    this.uploadImg(file.content, 'originSize',timestamp)
                    canvas.width = 110 * 3
                    canvas.height = 62 * 3
                    context.drawImage(img, 0, 0, canvas.width, canvas.height)
                    if(file.file.size>200000){
                        file.content = canvas.toDataURL(file.file.type, 0.5) // 0.2为默认压缩质量
                    } else {
                        file.content = canvas.toDataURL(file.file.type, 1) // 0.2为默认压缩质量
                    }
                    this.uploadImg(file.content,'isThumbs', timestamp)
                }
            }
        },
        // 上传图片到服务器
        uploadImg(imgBase64, type, timestamp){
            let isThumbs = type === 'isThumbs' ? true : false
            upload({ imgBase64: imgBase64, isThumbs: isThumbs, timestamp: timestamp }).then((res) => {
                let resImgUrl = res.data.data.imgUrl
                // ---图片路径用逗号隔开---
                if(!isThumbs){
                    // 原图
                    if(this.imgUrl){
                        let imgUrlList
                        imgUrlList = this.imgUrl.split(',')
                        imgUrlList.push(resImgUrl)
                        this.imgUrl = imgUrlList.join(",")
                    } else {
                        this.imgUrl = resImgUrl
                    }
                } else {
                    // 缩略图
                    if(this.miniImgUrl && isThumbs){
                        let miniImgUrlList
                        miniImgUrlList = this.miniImgUrl.split(',')
                        miniImgUrlList.push(resImgUrl)
                        this.miniImgUrl = miniImgUrlList.join(",")
                    } else {
                        this.miniImgUrl = resImgUrl
                    }
                }
                // 判断是缩略图还是原图
                if(!isThumbs){
                    // 可编辑文本更新
                    let editContext = document.getElementById('editContext') //获得dom对象
                        // editContext.appendChild(document.createElement("br"))
                    let contextImg = document.createElement("img")	//创建一个img元素
                        contextImg.src = resImgUrl //给img元素的src属性赋值
                        contextImg.style = 'max-width:100%;object-fit:cover;padding:0;box-sizing:border-box;display:block;margin:10px auto;'
                        contextImg.setAttribute('id', resImgUrl)
                        contextImg.addEventListener('click' ,()=>{
                            this.$refs.deleteImg.show()
                            this.$refs.deleteImg.bindImgId(resImgUrl)
                        })
                        editContext.appendChild(contextImg)
                    // 换至下一行
                        editContext.appendChild(document.createElement("br"))
                    // 将获得焦点的光标移动到最后的输入位置
                    let range = document.createRange()
                        range.selectNodeContents(document.getElementById('editContext'))
                        range.collapse(false)
                    let sel = window.getSelection()
                        sel.removeAllRanges()
                        sel.addRange(range)
                        this.context_html = document.getElementById('editContext').innerHTML
                }
            }).catch((error) => {
                console.warn(error)
            })
        },
        deleteImg(imgUrl){
            // 更新this.imgUrl
            let imgUrlArr = this.imgUrl.split(',')
            let tempArr = imgUrlArr.filter((item) => {
                return item !== imgUrl
            })
            this.imgUrl = tempArr.join(',')
            // 更新this.miniImgUrl
            let miniImgUrlArr = this.imgUrl.split(',')
            let miniTempArr = miniImgUrlArr.filter((item) => {
                return item !== 'mini-' + imgUrl
            })
            this.miniImgUrl = miniTempArr.join(',')
            // 删除已上传到服务器的图片
            deleteUpload({ imgUrl: imgUrl }).then(() => {
                document.getElementById(imgUrl).remove()
            }).catch((error) => {
                console.warn(error)
            })
        },
        focusHandler(e){
            this.focusElement = e.target.tagName // 返回标签名称，TEXTAREA或DIV
        },
        keydown(){
            if(this.focusElement === 'DIV'){
                const selection = window.getSelection() || document.getSelection();
                if (selection) {
                    var range = selection.getRangeAt(0)
                    var preCaretRange = range.cloneRange();//克隆一个选中区域
                    preCaretRange.selectNodeContents(document.getElementById('editContext'));//设置选中区域的节点内容为当前节点
                    preCaretRange.setEnd(range.endContainer, range.endOffset);  //重置选中区域的结束位置
                    // let caretOffset = preCaretRange.toString().length;
                    // console.log(document.getElementById('editContext').innerText, preCaretRange.toString())
                    // if(document.getElementById('editContext').innerText.length - caretOffset < 150){
                    //     document.body.scrollTop = document.documentElement.scrollTop =  document.documentElement.scrollHeight || document.body.scrollHeight;
                    // }
                }
            }
        },
        addLinkToContext(){
            // 光标在文章内容处才能添加链接
            if(this.focusElement === 'DIV'){
                this.cursorRange = window.getSelection().getRangeAt(0) // 保存光标对象
                this.$refs.link.show()
            }
        },
        showLabel(){
            this.labelArr = this.labelArrTemp
            this.$store.commit('Set_ShowLabel_State', true)
        },
        // 添加标签
        addLabel(){
            this.labelArrTemp = this.labelArr
            let label = this._.filter(this.labelArr, ['selected', true]);
            let temp = label.shift()
            this.selectedTopic.name = temp.title
            this.selectedTopic.id = temp.id
            setTimeout(()=>{
                document.getElementById('editContext').innerHTML = this.context_html
            },0)
        },
        // 取消添加标签
        cancelSelect(){
            setTimeout(()=>{
                document.getElementById('editContext').innerHTML = this.context_html
            },0)
        },
        // 单选
        selectPanel(id){
            let tempArr = []
            this.initLabel() // 初始化标签
            this.labelArr.forEach((item)=>{
                let temp = {}
                temp.id = item.id
                temp.title = item.title
                temp.showOptions = item.showOptions
                if(id === item.id){
                    temp.selected = !item.selected
                } else {
                    temp.selected = item.selected
                }
                temp.options = item.options
                tempArr.push(temp)
            })
            this.labelArr = tempArr
            return false;
        },
        selectPanelDB(id){
            this.selectPanel(id)
            this.addLabel()
            this.$store.commit('Set_ShowLabel_State', false)
            this.$nextTick(()=>{
                if(this.$store.state.userAgent === 'PC'){
                    document.getElementById("create-box").style.cssText= 'margin-top: 20px; height:100%;';
                }
            })
        },
        domClick(e){
            if(e.target.id === 'headerCreate'){
                this.showToolBar = false
            }
        },
        deleteLabel(){
            this.initLabel() // 初始化标签
            this.labelArrTemp = this.labelArr
            this.selectedTopic = {}
        },
        handlePaste(e) {
            e.preventDefault();
            // 获取粘贴的纯文本内容，包含换行符
            const textData = e.clipboardData.getData("text/plain");
            // 将文本内容按换行符拆分成行
            const lines = textData.split(/\r?\n/).reverse();
            // 获取当前选定范围
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            // 清除选定范围内的内容和格式
            range.deleteContents();
            // 插入文本节点并保留换行符
            for (let i = 0; i < lines.length; i++) {
                if (i > 0) {
                    range.insertNode(document.createElement("br")); // 插入换行符
                }
                range.insertNode(document.createTextNode(lines[i])); // 插入文本节点
            }
            // 将光标移动到文本末尾
            const newRange = document.createRange();
            newRange.selectNodeContents(range.endContainer);
            newRange.collapse(false);
            selection.removeAllRanges();
            selection.addRange(newRange);
            this.context = document.getElementById('editContext').textContent
            this.context_html = document.getElementById('editContext').innerHTML
            this.clearFormatting();
        },
        // 清除格式
        clearFormatting() {
            const selection = window.getSelection();
            const range = selection.getRangeAt(0);
            const wrapper = document.createElement("div");
            // const nodesToRemove = [];

            wrapper.appendChild(range.cloneContents());

            const children = wrapper.querySelectorAll("*");
            for (var i = 0; i < children.length; i++) {
            const child = children[i];
                child.removeAttribute("style");
                child.removeAttribute("class");
                child.removeAttribute("id");
                child.removeAttribute("title");
                child.removeAttribute("data-*");
            }

            const html = wrapper.innerHTML;

            range.deleteContents();
            range.insertNode(document.createRange().createContextualFragment(html));
        },
    }
}
</script>

<style scoped lang="scss">
.create-box{
    background: #fff;
    padding-top:55px;
    max-width: 625px;
    margin: 0 auto;
    // min-height: 100vh;
    // box-sizing: border-box;
    .titleBox{
        padding: 10px 20px 0 15px;
        position: relative;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
    .titleBox i{
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #797291;
        line-height: 17px;
        position: absolute;
        right: 10px;
        bottom: 5px;
    }
    .textarea{
        width: 100%;
        font-size: 25px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #121212;
        line-height: 25px;
        outline: 0;
        word-wrap: break-word;
        overflow-x: hidden;
        overflow-y: auto;
    }
    input::placeholder,textarea::placeholder {
        font-weight: 400;
        color: rgba(121,114,145,0.6);
        font-family:  -apple-system,BlinkMacSystemFont,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Segoe UI","PingFang SC","Hiragino Sans GB","Microsoft YaHei","Helvetica Neue",Helvetica,Arial,sans-serif;
    }
    input,textarea{
        border: none;
        resize:none;
        outline:none;
        min-height: 30px;
        padding: 0;
    }
    .content{
        padding: 10px 15px 6px 15px;
        min-height: calc(100vh - 110px);
        box-sizing: border-box;
        padding-bottom: 110px;
        width: 100%;
        box-sizing: border-box;
        border: none;
        resize:none;
        outline:none;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        // line-height: 24px;
        // min-height: 200px;
        text-align: left;
        caret-color:#ea6f7b;
        color: #121212;
    }
    .content >>> img{
        width: 100%;
        height: auto;
    }
    input::placeholder,textarea::placeholder {
        font-weight: 400;
    }
    .content:empty::before {
        content: attr(placeholder);
    }
    div[contenteditable]:empty:before{
        content: attr(placeholder);
        color: rgba(121,114,145,0.6);
    }
    .edit-footer {
        position: fixed;
        text-align: left;
        background: #eee;
        z-index: 999;
        bottom: 0;
        left: 0;
        right: 0;
        max-width: 625px;
        margin: 0 auto;
        img {
            margin: 10px 5px 5px 25px;
        }
        img:nth-child(2) {
            margin: 7px 5px 7px 25px;
        }
        .row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 15px;
            .selected-label {
                border: 1px solid #ec745b;
                border-radius: 5px;
                padding: 5px;
                margin: 5px 0;
                color: #ec745b;
            }
        }
        .create-button {
            margin: 5px;
            background: #ec745b;
            width: 90px;
            height: 35px;
            line-height: 35px;
            border-radius: 50px;
            color: #fff;
            font-size: 18px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .white-layout {
        background: #fff;
        position: absolute;
        z-index: -1;
        height: 100vh;
    }
}

</style>