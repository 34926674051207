<template>
    <div v-show="isShow">
        <div class="delete-dialog-box" @click="deleteImg">
            删除图片
        </div>
        <div class="shadow" @click="show"></div>
    </div>
</template>

<script>
export default {
  name: 'DeleteImg',
  components: {
  },
  data(){
      return {
          isShow: false,
          imgId:''
      }
  },
  methods:{
        show(){
            this.isShow = !this.isShow
        },
        deleteImg(){
            this.show()
            this.$emit('deleteImg', this.imgId)
        },
        bindImgId(id){
            this.imgId = id
        }
  },
  props:{

  }
}
</script>

<style scoped lang="scss">
.shadow {
    position: fixed;
    z-index: 999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.3);
}
.delete-dialog-box {
    width: calc(100% - 80px);
    height: 50px;
    line-height: 50px;
    margin: 0 40px;
    color: #000;
    background: #FFF;
    text-align: center;
    font-size: 20px;
    position: fixed;
    top: 50%;
    z-index: 1111;
}
</style>
